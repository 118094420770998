@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&family=Lobster&display=swap');

body {
  margin: 0;
  font-family: 'Lobster', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Styles */

@import url('https://fonts.googleapis.com/css2?family=Pattaya&family=Poppins:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

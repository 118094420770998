@import url('https://use.typekit.net/xon2wsd.css');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Fira+Sans+Extra+Condensed:wght@300&family=Grand+Hotel&family=Lobster&family=Montserrat&family=Reem+Kufi+Fun&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Reem Kufi Fun', sans-serif;
}

.codeInputContainer {
  padding: 16px;
}

.codeInput {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family:
    SF Pro Text,
    SF Pro Icons,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff9d4;
  background-clip: padding-box;
}

.codeInput:focus {
  appearance: none;
  outline: 0;
  box-shadow: 3px 3px 0 2px rgb(131 192 253 / 50%);
}

/*
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext

body {
  margin: 0;
  padding: 0;
  font-family: 'Lobster', cursive;
}


box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  */
